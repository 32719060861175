import React, { Component } from 'react'; //eslint-disable-line no-unused-vars
import { Navigate } from 'react-router-dom';

import { FaParking } from 'react-icons/fa';
import { checkLoginStatus } from '../api/apiUtils';

import './Login.css';
import TextFieldBox from './inputs/TextFieldBox';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: false,
      errorTxt: 'Login failure',
      valid: false,
      trigger: false,
      system: '',
    };
  }
  componentDidMount() {
    console.log(this.props);
    if (this.props.match && Object.keys(this.props.match).length > 0) {
      if (this.props.match.system) {
        localStorage.setItem('system', this.props.match.system);
        this.setState({ system: this.props.match.system });
      }
    }
  }

  componentDidUpdate() {}
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  isValid() {
    const { email, password } = this.state;
    return email.length > 0 && password.length > 6;
  }

  render() {
    console.log(this.props);
    const { system, trigger } = this.state;
    const setEmail = (email) => {
      //console.log(email);
      this.setState({ email: email });
      setTimeout(() => {
        this.setState({ valid: this.isValid() });
      }, 400);
    };

    const setPassword = (pw) => {
      this.setState({ password: pw });
      setTimeout(() => {
        this.setState({ valid: this.isValid() });
      }, 400);
    };

    const handleLogin = () => {
      const { email, password, valid } = this.state;

      if (!valid) return;

      // this.props.setUserName(email);
      this.props.requestLogin(email, password);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
    };

    const { error, errorTxt, valid } = this.state;

    if (checkLoginStatus() === true) {
      if (this.props.auth.changePassword === false) {
        const hostElements = window.location.hostname.split('.');
        if (hostElements.length >= 4) {
          return <Navigate to={window.webapp.rootPath + '/map/' + hostElements[0]} />;
        } else return <Navigate to={window.webapp.rootPath + '/main'} />;
        //    window.location.assign(window.webapp.rootPath + '/main');
      } else return <Navigate to={window.webapp.rootPath + '/change'} />;
    }

    if (this.props.auth.passwordChange === 'changed') {
      // window.location.reload();
    }

    // console.info('system', system);
    let service = '';

    const hostName = window.location.hostname;
    service = hostName.replace('.etsiparkki.fi', '');
    console.info(window.location.hostname, service);
    localStorage.setItem('service', service);

    return (
      <div
        className="main"
        style={{
          overflow: 'hidden',
          display: 'flex',
          flexWrap: 'wrap',
        }}>
        <div
          className="header"
          style={{
            maxWidth: '100%',
            width: '100%',
            maxHeight: '20vh',
            height: '20vh',
          }}>
          <h2 className="headerText">
            Please login <FaParking></FaParking>
          </h2>
        </div>

        <div
          className="login"
          style={{
            maxWidth: '100%',
            width: '100%',
            maxHeight: '70vh',
            height: '70vh',

            //    backgroundImage: `url(${backgroundImage})`,
            //    opacity: '0.5',
          }}>
          <div className="inputs"></div>
          <form onSubmit={handleSubmit}>
            <div className="credentials">
              <div>
                <TextFieldBox
                  style={{ width: '300px', backgroundColor: 'white' }}
                  label={'username'}
                  autoComplete="username"
                  className="username"
                  id="username"
                  inputType="text"
                  valueChange={(e) => setEmail(e)}></TextFieldBox>
              </div>
              <div>
                <TextFieldBox
                  style={{ width: '300px', backgroundColor: 'white' }}
                  label={'password'}
                  type={'password'}
                  autoComplete="current-password"
                  className="password"
                  id="password"
                  inputType="password"
                  valueChange={(e) => setPassword(e)}></TextFieldBox>
              </div>
            </div>

            <div style={{ marginTop: '10px' }}>
              <button className="loginButton" type="reset" onClick={handleLogin} disabled={!valid}>
                Login
              </button>
            </div>
          </form>

          <div className="forgotLink">{false ? <a href={window.location.origin + window.webapp.rootPath + '/forgot/request'}>forgot password?</a> : null}</div>
          <div className="adLogin">
            <a
              href={
                process.env.NODE_ENV && process.env.NODE_ENV === 'development'
                  ? 'http://localhost:8090/api/resources/user/login/msad?target=iot&service=parkandride'
                  : 'https://etsiparkki.fi/api/resources/user/login/msad?target=iot&service=' + service
              }>
              AD Login
            </a>
          </div>
          <div>{error ? <h3>{errorTxt}</h3> : null}</div>
          <div>{this.props.auth.accountLocked === true ? <label>{'Account locked. Try again later.'}</label> : null}</div>
        </div>
        <div
          className="footer"
          style={{
            maxWidth: '100%',
            width: '100%',
            maxHeight: '10vh',
            height: '10vh',
            //float: 'left',
            //  flex: '1 0 100%',
            //    flexGrow: 1,
            //  position: 'relative',
          }}></div>
        <div className="backgroundImage">
          <div style={{}}></div>
        </div>
      </div>
    );
  }
}

export default Login;
//style={{ backgroundImage: `url(${backgroundImage})`, opacity: '0.5', position: 'absolute', left: '0', top: '0', width: '100%', height: 'auto' }}
//http://localhost:8090/api/resources/user/login/msad
