import { getAssets, setAssetData } from '../api/assets';
import { setAuthFailure } from '../actions/auth';
import { setNotification } from '../actions/notifications';
export const SET_ASSETS = 'SET_ASSETS';
export const SET_ASSETS_WS = 'SET_ASSETS_WS';

export function setAssets(data) {
  return {
    type: SET_ASSETS,
    value: data,
  };
}

export function setAssetsWs(data) {
  return {
    type: SET_ASSETS_WS,
    value: data,
  };
}

export function requestAssets(city) {
  return (dispatch) =>
    getAssets(city)
      .then((data) => {
        dispatch(setAssets(data));
        return data;
      })
      .catch((error) => {
        console.error('fetch failed');
        dispatch(setNotification({ key: 'Request failed.', type: 'error' }));
        if (error.response && error.response.status === 403) {
          dispatch(setAuthFailure(true));
        }
      });
}

export function postAssetData(data) {
  return (dispatch) =>
    setAssetData(data)
      .then((data) => {
        dispatch(setNotification({ key: 'Request OK.', type: 'success' }));
        return data;
      })
      .catch((error) => {
        console.error('fetch failed');
        dispatch(setNotification({ key: 'Request failed.', type: 'error' }));
      });
}
