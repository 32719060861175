/**
 * @module login
 * @desc login related API calls
 */

import join from 'url-join';
import { validateResponse, toJson, getToken, getRefreshToken, removeToken, removeRefresh, getSystemId, getDesiredRole } from './apiUtils.js';
import { API_ROOT } from './apiUtils.js';

const LOGIN_ROOT = join(API_ROOT, '/parking/v1');

/**
 * Get first time usage status (is the ftu already done)
 */
export function postLogin(email, password) {
  const path = join(LOGIN_ROOT, 'login');
  const system = getSystemId();
  const role = getDesiredRole();
  return fetch(path, {
    //  credentials: 'include',
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: { 'content-type': 'application/json' },
    body: role.length > 0 ? JSON.stringify({ email, password, system, role }) : JSON.stringify({ email, password, system }),
    credentials: 'include',
  })
    .then(validateResponse)
    .then(toJson);
}

export function postLogout() {
  const path = join(LOGIN_ROOT, 'logout');

  let token = getRefreshToken();
  const system = getSystemId();
  removeToken();
  removeRefresh();
  return fetch(path, {
    //  credentials: 'include',
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ system }),
    credentials: 'include',
  })
    .then(validateResponse)
    .then(toJson);
}

export function postToken(refreshtoken) {
  const path = join(LOGIN_ROOT, 'token');

  return fetch(path, {
    //  credentials: 'include',
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ refreshtoken }),
  })
    .then(validateResponse)
    .then(toJson);
}

export function postChangePassword(password, oldPassword) {
  const path = join(LOGIN_ROOT, 'changepw');
  let token = getToken();
  return fetch(path, {
    //  credentials: 'include',
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: { authorization: token, 'content-type': 'application/json' },
    body: JSON.stringify({ password, oldPassword }),
    credentials: 'include',
  }).then(validateResponse);
}

export function postForgotPasswordRequest(username) {
  const path = join(LOGIN_ROOT, 'forgotpw/request');

  return fetch(path, {
    //  credentials: 'include',
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ email: username }),
  }).then(validateResponse);
}

export function postForgotPasswordChange(token, password) {
  const path = join(LOGIN_ROOT, 'forgotpw/change');

  return fetch(path, {
    //  credentials: 'include',
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: { authorization: token, 'content-type': 'application/json' },
    body: JSON.stringify({ password: password }),
  }).then(validateResponse);
}

export function getSessionInfo() {
  let path = join(API_ROOT, 'session/info');

  let token = getToken();

  return fetch(path, {
    //  credentials: 'include',
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    headers: { authorization: token },
    credentials: 'include',
  })
    .then(validateResponse)
    .then(toJson);
}

export function getRoleTypes() {
  let path = join(API_ROOT, 'groups/roletypes');

  let token = getToken();

  return fetch(path, {
    //  credentials: 'include',
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    headers: { authorization: token },
    credentials: 'include',
  })
    .then(validateResponse)
    .then(toJson);
}
