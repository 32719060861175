import { SET_ASSETS, SET_ASSETS_WS } from '../actions/assets';

const defaultState = {
  assets: {},
};

function assets(state = defaultState, action) {
  // console.log(action);
  switch (action.type) {
    case SET_ASSETS:
      return {
        ...state,
        assets: action.value,
      };

    case SET_ASSETS_WS:
      console.log('SET_ASSETS_WS');

      //  let result = _.mergeWith(state.viewdata, action.value[key], (a, b) => (_.isArray(b) ? b : undefined));
      let result = state.assets;
      for (const [id, value] of Object.entries(action.value)) {
        result[id] = value;
      }
      return {
        ...state,
        assets: result,
      };
    default:
      return state;
  }
}

export default assets;
