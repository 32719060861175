import dayjs from 'dayjs';
import { SET_NOTIFICATION } from '../actions/notifications';

const defaultState = {
  items: {},
  latest: { key: '', time: 0, type: '' },
};

function notifications(state = defaultState, action) {
  switch (action.type) {
    case SET_NOTIFICATION:
      const notificationItem = action.value.key.length > 0 ? { ...action.value, time: dayjs().valueOf() } : {};
      let items = state.items;
      if (action.value.key.length > 0) items[action.value.key] = notificationItem;
      return {
        ...state,
        items: items,
        latest: notificationItem,
      };
    default:
      return state;
  }
}

export default notifications;
