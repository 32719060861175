import { connect } from 'react-redux';
import ChangePassword from '../components/ChangePassword';

import { requestChangePassword as requestChangePasswordAction, requestLogout as requestLogoutAction } from '../actions/auth';

function mapStateToProps({ auth }) {
  return {
    auth: auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestChangePassword: (password, oldPassword) => dispatch(requestChangePasswordAction(password, oldPassword)),
    requestLogout: () => dispatch(requestLogoutAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
