import React from 'react';

import { createBrowserHistory } from 'history';
import { Router, Route, Routes, Navigate, BrowserRouter, useParams } from 'react-router-dom';
import './index.css';
import './i18n';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
//import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { createRoot } from 'react-dom/client';

import createRootReducer from './reducers';
//import { store as toolkitStore } from './store';
import Login from './containers/Login';
import ChangePassword from './containers/ChangePassword';
import { onMessage } from './api/socketIO';
import { setAssetsWs } from './actions/assets';
const Main = React.lazy(() => import('./containers/Main'));
const history = createBrowserHistory();
const store = createStore(createRootReducer(history), applyMiddleware(thunk));
//const store = toolkitStore; //using toolkit, it requires some refactoring to make it work
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
window.webapp = {};
window.webapp.rootPath = '';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
} else {
  window.getState = store.getState;
}

onMessage('assetData', function (data) {
  //console.log(data);
  store.dispatch(setAssetsWs(data));
});

onMessage('wsDisconnected', function (data) {
  //console.log(data);
  store.dispatch(setAssetsWs({}));
});

root.render(
  <Provider store={store}>
    <BrowserRouter history={history}>
      <Routes history={history}>
        <Route exact path={window.webapp.rootPath + '/login'} element={<Login></Login>} />
        <Route exact path={window.webapp.rootPath + '/change'} element={<ChangePassword></ChangePassword>} />
        <Route exact path={window.webapp.rootPath + '/login/:admin'} element={<LoginF></LoginF>} />
        <Route exact path={window.webapp.rootPath + '/login/:system/:token/:rtoken'} element={<LoginF></LoginF>} />
        <Route exact path={window.webapp.rootPath + '/main'} element={<Main></Main>} />
        <Route path={window.webapp.rootPath + '/map/:city'} element={<MainF></MainF>} />
        <Route path={'*'} element={<Navigate to={'/login'}></Navigate>} />
      </Routes>
    </BrowserRouter>
  </Provider>
);
function LoginF() {
  let params = useParams();
  return <Login match={params}></Login>;
}

function MainF() {
  let params = useParams();
  return <Main match={params}></Main>;
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
/*





*/
