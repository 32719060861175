import { SET_LOGS } from '../actions/logs';

const defaultState = {
  logs: [],
};

function logs(state = defaultState, action) {
  switch (action.type) {
    case SET_LOGS:
      return {
        ...state,
        logs: action.value,
      };

    default:
      return state;
  }
}

export default logs;
