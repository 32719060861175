import {
  MODIFY_LOGIN_STATE,
  SET_AUTH_FAILURE,
  MODIFY_LOGOUT_STATE,
  MODIFY_CHANGE_PASSWORD_STATE,
  SET_ACCOUNT_LOCKED_STATE,
  SET_USERNAME,
  SET_SESSION_INFO,
  SET_ROLE_TYPES,
} from '../actions/auth';

const defaultState = {
  token: '',
  refreshToken: '',
  changePassword: false,
  passwordChange: '',
  passwordValidationMessages: [],
  accountLocked: false,
  username: '',
  authFailure: false,
  roleTypes: [],
};

function auth(state = defaultState, action) {
  switch (action.type) {
    case MODIFY_LOGIN_STATE:
      if (action.value.email) {
        localStorage.setItem('username', action.value.email);
      }

      return {
        ...state,
        ...action.value,
      };

    case MODIFY_LOGOUT_STATE:
      localStorage.setItem('token', '');
      localStorage.setItem('refreshToken', '');
      localStorage.setItem('username', '');
      localStorage.setItem('system', '');
      return {
        ...state,
      };
    case SET_AUTH_FAILURE:
      localStorage.setItem('token', '');
      localStorage.setItem('username', '');
      localStorage.setItem('system', '');

      return {
        ...state,
        authFailure: action.value,
      };
    case MODIFY_CHANGE_PASSWORD_STATE:
      return {
        ...state,
        ...action.value,
      };
    case SET_ACCOUNT_LOCKED_STATE:
      return {
        ...state,
        ...action.value,
      };

    case SET_USERNAME:
      return {
        ...state,
        username: action.value,
      };
    case SET_SESSION_INFO:
      if (action.value.email) {
        localStorage.setItem('username', action.value.email);
      }
      return {
        ...state,
        session: action.value,
      };
    case SET_ROLE_TYPES:
      return {
        ...state,
        roleTypes: action.value,
      };
    default:
      return state;
  }
}

export default auth;
