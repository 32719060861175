import join from 'url-join';
import { validateResponse, toJson, getToken, checkAuthorization, removeToken } from './apiUtils.js';
import { LOGS_ROOT } from './apiUtils.js';

export function getLogs(params) {
  const paramsURL = params ? '?start=' + params.start + '&stop=' + params.stop : '';
  let path = params ? join(LOGS_ROOT, 'history' + paramsURL) : join(LOGS_ROOT, 'history');
  let token = getToken();

  return fetch(path, {
    //  credentials: 'include',
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    credentials: 'include',
  })
    .then(validateResponse)
    .then(toJson);
}

export function writeExceptionLog(data) {
  let path = join(LOGS_ROOT, 'write/ui/exception');
  console.log(JSON.stringify(data), typeof data);

  return fetch(path, {
    //  credentials: 'include',
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ data: data }),
  })
    .then(validateResponse)
    .then(toJson);
}
