import React, { Component } from 'react'; //eslint-disable-line no-unused-vars
import { Navigate } from 'react-router-dom';

import InputBox from '../components/inputs/InputBox';

import { MdDirectionsBike } from 'react-icons/md';
import { getToken } from '../api/apiUtils';

import './ChangePassword.css';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      oldPassword: '',
      password2: '',
      error: false,
      errorTxt: 'check password',
      valid: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {}

  componentDidUpdate() {
    console.log(this.props.auth);
  }

  // Whenever an input changes value, change the corresponding state variable
  handleInputChange(value, event, key) {
    event.preventDefault();
    //const target = event.target;

    this.setState({
      [key]: value,
    });
  }

  isValid() {
    const { password2, password, oldPassword } = this.state;
    //  console.log(password2, password);
    return password2.length > 0 && password.length > 0 && password === password2 && oldPassword !== password;
  }

  render() {
    const handleChange = () => {
      const { password, oldPassword } = this.state;
      const validity = this.isValid();

      this.setState({
        error: !validity,
      });

      if (validity === true) {
        this.props.requestChangePassword(password, oldPassword);
      }
    };

    const handleSubmit = (event) => {
      event.preventDefault();
    };

    const { error, errorTxt, valid } = this.state;

    let notifyText = '';
    if (this.props.auth.passwordChange === 'changed') {
      notifyText = 'password changed';

      setTimeout(() => {
        console.log('about to logout');
        this.props.requestLogout();
      }, 1700);
    }
    if (this.props.auth.passwordChange === 'failed') notifyText = 'password change failed';

    if (getToken().length < 10) {
      return <Navigate to={window.webapp.rootPath + '/login'} />;
    }

    let validationArray = this.props.auth.passwordValidationMessages;
    let errorMessages = [];
    validationArray.forEach((element, idx) => {
      errorMessages.push(
        <div>
          <label>{element.message}</label>
        </div>
      );
    });

    return (
      <div
        className="main"
        style={{
          overflow: 'hidden',
          display: 'flex',
          flexWrap: 'wrap',
          //width: '100%',
          // height: '100%',
          //    alignItems: 'center',
          //     justifyContent: 'center',
          //      postition: 'absolute',
          //flexFlow: 'row wrap',
        }}>
        <div
          className="header"
          style={{
            maxWidth: '100%',
            width: '100%',
            maxHeight: '10vh',
            height: '10vh',
          }}>
          <h1 className="headerText">etsiparkki.fi</h1>
        </div>

        <div
          className="login"
          style={{
            maxWidth: '100%',
            width: '100%',
            maxHeight: '85vh',
            height: '85vh',
          }}>
          <div className="inputs"></div>
          <form onSubmit={handleSubmit}>
            <div>
              <label>old password</label>
              <InputBox
                name="password"
                className="password"
                id="oldPassword"
                inputType="password"
                handleChange={(value, e) => this.handleInputChange(value, e, 'oldPassword')}></InputBox>
            </div>
            <div>
              <label>new password</label>
              <InputBox
                name="password"
                className="password"
                id="password"
                inputType="password"
                handleChange={(value, e) => this.handleInputChange(value, e, 'password')}></InputBox>
            </div>
            <div>
              <label>re-type password</label>
              <InputBox
                name="password2"
                className="password"
                id="password2"
                inputType="password"
                handleChange={(value, e) => this.handleInputChange(value, e, 'password2')}></InputBox>
            </div>
            <div style={{ marginTop: '10px' }}>
              <button className="searchBarButton" type="reset" onClick={handleChange} disabled={false}>
                change
              </button>
            </div>
          </form>
          <div>{error ? <h3 className="errorText">{errorTxt}</h3> : null}</div>
          <div>{notifyText.length > 0 ? <h3 className="errorText">{notifyText}</h3> : null}</div>
          <div>{errorMessages.length > 0 ? <div>{errorMessages}</div> : null}</div>
        </div>
        <div
          className="footer"
          style={{
            maxWidth: '100%',
            width: '100%',
            maxHeight: '5vh',
            height: '5vh',
            //float: 'left',
            //  flex: '1 0 100%',
            //    flexGrow: 1,
            //  position: 'relative',
          }}></div>
      </div>
    );
  }
}

export default ChangePassword;
