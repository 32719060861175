const developmentTag = 'development';
const developmentRoot = 'localhost';
export const API_ROOT =
  process.env.NODE_ENV && process.env.NODE_ENV === developmentTag ? 'http://' + developmentRoot + ':8080/api/resources' : 'https://etsiparkki.fi/api/resources';

export const ALARMS_ROOT =
  process.env.NODE_ENV && process.env.NODE_ENV === developmentTag
    ? 'http://' + developmentRoot + ':8080/api/resources/alarms'
    : 'https://etsiparkki.fi/api/resources/alarms';
export const LOGS_ROOT =
  process.env.NODE_ENV && process.env.NODE_ENV === developmentTag
    ? 'http://' + developmentRoot + ':8080/api/resources/logs'
    : 'https:///etsiparkki.fi/api/resources/logs';
export const CONTENT_ROOT = '';
export const WEBSOCKET_ROOT = process.env.NODE_ENV === developmentTag ? 'ws://' + developmentRoot + ':8999' : 'wss://etsiparkki.fi';

export function validateResponse(response) {
  if (response.status && (response.status === 403 || response.status === 423)) {
    removeToken();
    return Promise.reject({ response: response });
  }

  if (response.ok) {
    return response;
  } else {
  }

  return response.json().then((data) => Promise.reject({ response: response, data: data }));
  /*  .catch((data) => {
      console.log('json convert error', data);
      Promise.reject({ response: response, data: data });
    });*/
}

export function toJson(response) {
  return response.json().catch(() => true);
}

export function test(response) {
  return response.json().catch(() => true);
}

export function getToken() {
  const token = localStorage.getItem('username');
  return token ? token : '';
}

export function checkLoginStatus() {
  const username = localStorage.getItem('username');
  const system = localStorage.getItem('system');
  return system !== '' || username !== '';
}

export function logout() {
  localStorage.setItem('token', '');
  localStorage.setItem('username', '');
  localStorage.setItem('system', '');
}

export function getRefreshToken() {
  const token = localStorage.getItem('refreshToken');
  return token ? token : '';
}

export function removeToken() {
  localStorage.setItem('token', '');
  return;
}

export function removeRefresh() {
  localStorage.setItem('refreshToken', '');
  return;
}

export function checkAuthorization(response) {
  if (response.status === 403) {
    localStorage.setItem('token', '');
  }
  console.log(response);
  return;
}

export function getSystemId() {
  const system = localStorage.getItem('system');
  return system ? system : '';
}

export function getDesiredRole() {
  const role = localStorage.getItem('systemrole');
  return role ? role : '';
}
