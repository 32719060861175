/**
 * @module login
 * @desc UI actions
 */
import { postLogin, postToken, postLogout, postChangePassword, postForgotPasswordRequest, postForgotPasswordChange, getSessionInfo, getRoleTypes } from '../api/auth.js';
/**
 * Action name for MODIFY\_UI\_STATE
 */
export const MODIFY_LOGIN_STATE = 'MODIFY_LOGIN_STATE';
export const MODIFY_LOGOUT_STATE = 'MODIFY_LOGOUT_STATE';
export const SET_AUTH_FAILURE = 'SET_AUTH_FAILURE';
export const MODIFY_CHANGE_PASSWORD_STATE = 'MODIFY_CHANGE_PASSWORD_STATE';
export const SET_ACCOUNT_LOCKED_STATE = 'SET_ACCOUNT_LOCKED_STATE';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_SESSION_INFO = 'SET_SESSION_INFO';
export const SET_ROLE_TYPES = 'SET_ROLE_TYPES';

export function modifyUserName(data) {
  return {
    type: SET_USERNAME,
    value: data,
  };
}

export function setUserName(username) {
  return (dispatch) => {
    dispatch(modifyUserName(username));
    localStorage.setItem('username', username);
  };
}

export function modifySessionInfo(data) {
  return {
    type: SET_SESSION_INFO,
    value: data,
  };
}

export function requestSessionInfo() {
  return (dispatch) =>
    getSessionInfo()
      .then((data) => {
        dispatch(modifySessionInfo(data));
        return data;
      })
      .catch((error) => {
        console.error('fetch failed', error);
      });
}

export function modifyRoleTypes(data) {
  return {
    type: SET_ROLE_TYPES,
    value: data,
  };
}

export function requestRoleTypes() {
  return (dispatch) =>
    getRoleTypes()
      .then((data) => {
        dispatch(modifyRoleTypes(data));
        return data;
      })
      .catch((error) => {
        console.error('fetch failed', error);
      });
}

export function setLogin(data) {
  return {
    type: MODIFY_LOGIN_STATE,
    value: data,
  };
}

/**
 * Action to modify the ui state
 * @param key { string|number } - Key to modify
 * @param value { string|number } - Value
 */
export function modifyLoginState(key, value) {
  return {
    type: MODIFY_LOGIN_STATE,
    key,
    value,
  };
}

export function setAuthFailure(data) {
  return {
    type: SET_AUTH_FAILURE,

    value: data,
  };
}

export function setAccountLocked(data) {
  return {
    type: SET_ACCOUNT_LOCKED_STATE,

    value: data,
  };
}

export function requestLogin(username, password) {
  return (dispatch) =>
    postLogin(username, password)
      .then((data) => {
        dispatch(setLogin(data));
        dispatch(setAccountLocked({ accountLocked: false }));
        return data;
      })
      .catch((error) => {
        console.error('fetch failed', error);
        if (error.response && error.response.status === 423) dispatch(setAccountLocked({ accountLocked: true }));
        else dispatch(setAccountLocked({ accountLocked: false }));
      });
}

export function setLogout(data) {
  return {
    type: MODIFY_LOGOUT_STATE,
    value: data,
  };
}

export function requestLogout() {
  return (dispatch) =>
    postLogout()
      .then((data) => {
        dispatch(setLogout(data));
        return data;
      })
      .catch((error) => {
        console.error('fetch failed');
        dispatch(setAuthFailure(true));
      });
}

export function requestToken(refreshtoken) {
  return (dispatch) =>
    postToken(refreshtoken)
      .then((data) => {
        dispatch(setLogin(data));
        return data;
      })
      .catch((error) => console.error('fetch failed', error));
}

export function setChangePassword(data) {
  return {
    type: MODIFY_CHANGE_PASSWORD_STATE,
    value: data,
  };
}

export function requestChangePassword(password, oldPassword) {
  return (dispatch) =>
    postChangePassword(password, oldPassword)
      .then((data) => {
        dispatch(setChangePassword({ passwordChange: 'changed', passwordValidationMessages: [] }));
        return data;
      })
      .catch((error) => {
        console.log('password change failed', error);
        if (error.response && error.data && error.response.status === 406) {
          dispatch(setChangePassword({ passwordChange: 'failed', passwordValidationMessages: error.data }));
        } else dispatch(setChangePassword({ passwordChange: 'failed', passwordValidationMessages: [] }));

        console.error('fetch failed');
      });
}

export function requestForgotPasswordRequest(username) {
  return (dispatch) =>
    postForgotPasswordRequest(username)
      .then((data) => {
        return data;
      })
      .catch((error) => console.error('fetch failed', error));
}

export function requestForgotPasswordChange(token, password) {
  return (dispatch) =>
    postForgotPasswordChange(token, password)
      .then((data) => {
        dispatch(setChangePassword({ passwordChange: 'changed', passwordValidationMessages: [] }));
        return data;
      })
      .catch((error) => {
        console.error('fetch failed', error);
        if (error.response && error.data && error.response.status === 406) {
          dispatch(setChangePassword({ passwordChange: 'failed', passwordValidationMessages: error.data }));
        } else dispatch(setChangePassword({ passwordChange: 'failed', passwordValidationMessages: [] }));
      });
}
