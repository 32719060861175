import { getLogs, writeExceptionLog } from '../api/logs';
import { setAuthFailure } from '../actions/auth';
import { setNotification } from '../actions/notifications';
export const SET_LOGS = 'SET_LOGS';

export function setLogs(data) {
  return {
    type: SET_LOGS,
    value: data,
  };
}

export function requestLogs(params) {
  return (dispatch) =>
    getLogs(params)
      .then((data) => {
        dispatch(setLogs(data));
        return data;
      })
      .catch((error) => {
        console.error('fetch failed requestLogs');
        dispatch(setNotification({ key: 'Request failed', type: 'error' }));
        if (error.response && error.response.status === 403) {
          dispatch(setAuthFailure(true));
        }
      });
}

export function requestWriteExceptionLog(data) {
  console.log(data);
  return (dispatch) =>
    writeExceptionLog(data)
      .then((data) => {
        //  dispatch(setLogs(data));
        return data;
      })
      .catch((error) => {
        console.error('fetch failed requestLogs');
        dispatch(setNotification({ key: 'Request failed', type: 'error' }));
        if (error.response && error.response.status === 403) {
          dispatch(setAuthFailure(true));
        }
      });
}
