import { getMeasurements } from '../api/measurements';
import { setAuthFailure } from '../actions/auth';
import { setNotification } from '../actions/notifications';
export const SET_MEASUREMENTS = 'SET_MEASUREMENTS';

export function setMeasurements(data, params) {
  return {
    type: SET_MEASUREMENTS,
    value: { data: data, params: params },
  };
}

export function requestMeasurements(params) {
  return (dispatch) =>
    getMeasurements(params)
      .then((data) => {
        dispatch(setMeasurements(data, params));
        return data;
      })
      .catch((error) => {
        console.error('fetch failed', error);
        dispatch(setNotification({ key: 'Request failed', type: 'error' }));
        if (error.response && error.response.status === 403) {
          dispatch(setAuthFailure(true));
        }
      });
}
