import join from 'url-join';
import { validateResponse, toJson } from './apiUtils.js';
import { API_ROOT } from './apiUtils.js';

export function getMeasurements(params) {
  let path = join(API_ROOT, 'measurements/history');

  Object.keys(params).forEach((key, idx) => {
    if (idx !== 0) path = path + '&' + key + '=' + params[key];
    else path = path + '?' + key + '=' + params[key];
  });

  return fetch(path, {
    //  credentials: 'include',
    method: 'GET', // *GET, POST, PUT, DELETE, etc.

    credentials: 'include',
  })
    .then(validateResponse)
    .then(toJson);
}
