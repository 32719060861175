import React from 'react';

class InputBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidUpdate() {
    //console.log('props:', this.props);
  }

  handleChange = (event) => {
    this.props.handleChange(event.target.value, event);
  };

  handleCopyPaste = (event) => {
    this.props.handleChange(event.clipboardData.getData('text/plain'), event);
  };

  render() {
    return (
      <div>
        <input type={this.props.inputType} className="inputBox" autoComplete="off" onChange={this.handleChange}></input>
      </div>
    );
  }
}

export default InputBox;
