/**
 * @module websocket
 * @desc Websocket javascript API
 */

import SocketIo from 'socket.io-client';

import { WEBSOCKET_ROOT, logout } from './apiUtils';

/**
 * Websocket channels. Can be used with ``onMessage`` method.
 * @type { object }
 * @property time - Receives the system time (about three times a second)
 */

const messageHooks = {};
const messageFormatters = {
  // [channels.sensorState]: obj => obj // for example, if needed
};

/**
 * Set message hook for defined channel
 * @param { string } channel
 * @param { function } callback
 */
export function onMessage(channel, f) {
  if (!(f instanceof Function)) throw new Error('`f` is not a function!');
  messageHooks[channel] = f;
  // const c = sensorCache[channels[channel]];
  //if (c) f(c);
}

function connect() {
  const targetCity = localStorage.getItem('targetCity');
  const filter = targetCity && targetCity !== '' ? targetCity + '.*' : '*';
  const socket = SocketIo(WEBSOCKET_ROOT, {
    path: '/ws/parking/places/asset/data',
    query: { filter: 'parking.' + filter },
    withCredentials: true,
    transports: ['websocket'],
    timeout: 10000,
  });
  socket.on('connect', () => {});

  socket.on('connect_error', () => {
    console.warn('connect_error');
  });

  //this means, that server has decided to disconnect -> no auto reconnect.
  socket.on('disconnect', (reason) => {
    console.warn('disconnect', reason);
    logout();
    if ('wsDisconnected' in messageHooks) messageHooks['wsDisconnected']({ dummy: { connection: 'lost' } });
  });

  for (const [key, f] of Object.entries(messageHooks)) {
    socket.on(key, (arg, callback) => {
      f(arg);
      // callback('ok');
    });
  }

  socket.on('error', () => {});
}
setTimeout(() => {
  connect();
}, 200);
/**
 * Get system time
 */
/* export function getSystemTime() {
  return socketPromise.then(
    (socket) =>
      new Promise((resolve) => {
        socket.once(channels.time, (time) => resolve(new Date(time)));
      })
  );
} */
