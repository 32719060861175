import { connect } from 'react-redux';
import Login from '../components/Login';

import { requestLogin as requestLoginAction, setUserName as setUserNameAction } from '../actions/auth';

function mapStateToProps({ auth }) {
  return {
    auth: auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestLogin: (username, password) => dispatch(requestLoginAction(username, password)),
    setUserName: (username) => dispatch(setUserNameAction(username)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
