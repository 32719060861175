import { getAlarms } from '../api/alarms';
import { setAuthFailure } from '../actions/auth';
import { setNotification } from '../actions/notifications';
export const SET_ALARMS = 'SET_ALARMS';

export function setAlarms(data) {
  return {
    type: SET_ALARMS,
    value: data,
  };
}

export function requestAlarms(params) {
  return (dispatch) =>
    getAlarms(params)
      .then((data) => {
        dispatch(setAlarms(data));
        return data;
      })
      .catch((error) => {
        console.error('fetch failed');
        dispatch(setNotification({ key: 'Request failed', type: 'error' }));
        if (error.response && error.response.status === 403) {
          dispatch(setAuthFailure(true));
        }
      });
}
