import { SET_MEASUREMENTS } from '../actions/measurements';

const defaultState = {
  values: {},
};

function measurements(state = defaultState, action) {
  // console.log(action);
  switch (action.type) {
    case SET_MEASUREMENTS:
      const id = action.value.params.id;
      const value = action.value.data;
      state.values[id] = value;
      return {
        ...state,
        values: state.values,
      };

    default:
      return state;
  }
}

export default measurements;
