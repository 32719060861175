let fi = {
  translations: {
    directions: 'Reittiohje',
    about: 'About',
    info: 'Info',
    freeParking: 'Ilmaispysäköinti',
    discParking: 'Kiekkopysäköinti',
    tollParking: 'Maksullinen pysäköinti',
    typeCDparking: 'CD-tunnus',
    feedback: 'Palaute',
    specialParking: 'Erikoispysäköinti',
    privateArea: 'Yksityisalue',
    streetParking: 'Kadunvarsipysäköinti',
    parkinglot: 'Parkkipaikka',
    allFilters: 'Kaikki',
    searchPlaceHolder: 'Osoitehaku...',
    city: 'Kaupunki',
    segment: 'Segmentti',
    assetType: 'Alueen tyyppi',
    capacity: 'Kapasiteetti arvio',
    paymentType: 'Maksutyyppi',
    paymentZone: 'Maksuvyöhyke',
    reservedForTypes: 'Varattu ajoneuvoille',
    parkingCount: 'Käynnissä olevat pysäköinnit',
    parkingPlace: 'Pysäköintialue',
    discLimitedOnWeek: 'Rajoitus kiekolla arkena',
    discLimitedOnSat: 'Rajoitus kiekolla lauantaina',
    discLimitedOnSun: 'Rajoitus kiekolla sunnuntaina',
    restrictionAdditionalInformation: 'Rajoitusten lisätiedot',
    privateAreaInformation: 'Lisätiedot',
    limitedPaymentOnWeek: 'Maksullinen arkena',
    limitedPaymentOnSat: 'Maksullinen lauantaina',
    limitedPaymentOnSun: 'Maksullinen sunnuntaina',
    type: 'Tyyppi',
    maxAllowedTime: 'Suurin sallittu pysäköintiaika',
    freeInfo: 'Vapaa',
    fullInfo: 'Varattu',
    unknownInfo: 'Ei tiedossa',
    feedbackFormHeader: 'Mitä mieltä olet palvelusta?',
    otherFeedback: 'Muuta palautetta:',
    submitButton: 'Lähetä',
    missingFeedback: 'Valitse hymiö ja lähetä',
    feedbackThanks: 'Kiitos palautteesta!',
    otherInfo: 'Huom.',
    sharedInfoAccrossStreet: 'Paikkalukumäärä on yhteinen vastapuolella olevan alueen kanssa',
    disclaimerNotice: 'Varausastetieto on suuntaa antava',
    dataSource: 'Tietolähde',
    linkToMainPageText: 'Etsi parkkipaikka',
    specialPermissions: 'Erityisluvalla sallittu',
    LElicense: 'LE-lupa',
    CDplate: 'CD-tunnus',
    bicycle: 'Polkupyörä',
    advertisement: 'Mainos',
    space: 'tilaa',
    full: 'täynnä',
    cityPick: 'kaupunki',
    description: 'Kuvaus',
  },
  parkingCountIndicator: {
    free: 'TILAA',
    full: 'TÄYNNÄ',
    closed: 'SULJETTU',
  },
  alarms: {
    connectionError: 'Yhteysvika',
    controlConflict: 'Tila ei vastaa ohjausta',
    'in1loop1-loopFault': 'Sisäänajo 1 / silmukka 1, vika',
    'in1loop2-loopFault': 'Sisäänajo 1 / silmukka 2, vika',
    'out1loop1-loopFault': 'Ulosajo 2 / silmukka 1, vika',
    'out1loop2-loopFault': 'Ulosajo 2 / silmukka 2, vika',
  },
};

export default fi;
