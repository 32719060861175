import { SET_ALARMS } from '../actions/alarms';

const defaultState = {
  alarms: [],
};

function alarms(state = defaultState, action) {
  // console.log(action);
  switch (action.type) {
    case SET_ALARMS:
      return {
        ...state,
        alarms: action.value,
      };

    default:
      return state;
  }
}

export default alarms;
