let en = {
  translations: {
    directions: 'Directions',
    about: 'About',
    info: 'Info',
    freeParking: 'Free parking',
    discParking: 'Disc parking',
    tollParking: 'Toll parking',
    typeCDparking: 'CD parking',
    feedback: 'Feedback',
    specialParking: 'Special parking',
    privateArea: 'Private area',
    streetParking: 'Street parking',
    parkinglot: 'Parkinglot',
    allFilters: 'All filters',
    searchPlaceHolder: 'Search for address...',
    city: 'City',
    segment: 'Segment',
    assetType: 'Asset type',
    capacity: 'Capacity estimate',
    paymentType: 'Payment type',
    paymentZone: 'Payment zone',
    reservedForTypes: 'Reserved for types',
    parkingCount: 'Ongoing parking',
    parkingPlace: 'Parkingplace',
    discLimitedOnWeek: 'Disc limited on week',
    discLimitedOnSat: 'Disc limited on saturday',
    discLimitedOnSun: 'Disc limited on sunday',
    restrictionAdditionalInformation: 'Restrictions additional info',
    privateAreaInformation: 'Additional info',
    limitedPaymentOnWeek: 'Paid on weekdays',
    limitedPaymentOnSat: 'Paid on sataurday',
    limitedPaymentOnSun: 'Paid on sunday',
    type: 'Type',
    maxAllowedTime: 'Maximum allowed parking time',
    freeInfo: 'Free',
    fullInfo: 'Full',
    unknownInfo: 'Unknown',
    feedbackFormHeader: 'What do you think about the service?',
    otherFeedback: 'Other feedback:',
    submitButton: 'Submit',
    missingFeedback: 'Pick a smiley and submit',
    feedbackThanks: 'Thank you for feedback!',
    otherInfo: 'Other info',
    sharedInfoAccrossStreet: 'The number of places is common to the area opposite',
    disclaimerNotice: 'Occupancy information is indicative',
    dataSource: 'Datasource',
    linkToMainPageText: 'Find a parking space',
    specialPermissions: 'Special permission required',
    LElicense: 'Disabled parking space',
    CDplate: 'CD-plate',
    bicycle: 'Bicycle',
    advertisement: 'Ad',
    space: 'space',
    full: 'full',
    cityPick: 'pick city',
    description: 'Description',
  },
  parkingCountIndicator: {
    free: 'SPACES',
    full: 'FULL',
    closed: 'CLOSED',
  },
  alarms: {
    connectionError: 'Connection error',
    controlConflict: 'Control conflict',
  },
};

export default en;
