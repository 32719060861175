import { combineReducers } from 'redux';
//import { connectRouter } from 'connected-react-router';

import auth from '../reducers/auth.js';
import assets from '../reducers/parking.js';
import notifications from '../reducers/notifications';
import alarms from '../reducers/alarms';
import logs from '../reducers/logs.js';
import measurements from '../reducers/measurements';

const createRootReducer = (history) =>
  combineReducers({
    // router: connectRouter(history),
    assets,
    auth,
    notifications,
    alarms,
    logs,
    measurements,
  });

export default createRootReducer;
