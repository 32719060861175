import join from 'url-join';
import { validateResponse, toJson, getToken, checkAuthorization, removeToken } from './apiUtils.js';
import { ALARMS_ROOT } from './apiUtils.js';

export function getAlarms(params) {
  let paramsURL = params ? '?start=' + params.start + '&stop=' + params.stop : '';
  if (params && 'filter' in params) {
    if (paramsURL.length === 0) paramsURL = '?filter=' + params.filter;
    else paramsURL = paramsURL + '&filter=' + params.filter;
  }
  let path = params ? join(ALARMS_ROOT, 'history' + paramsURL) : join(ALARMS_ROOT, 'history');
  let token = getToken();

  return fetch(path, {
    //  credentials: 'include',
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    credentials: 'include',
  })
    .then(validateResponse)
    .then(toJson);
}
