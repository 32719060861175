import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

class TextFieldBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidUpdate() {
    //console.log('props:', this.props);
  }

  handleChange = (value) => {
    //  console.log(value);
    if (this.props.valueChange) this.props.valueChange(value);
  };

  handleCopyPaste = (event) => {
    //  this.props.handleChange(event.clipboardData.getData('text/plain'), event);
  };

  render() {
    const label = this.props.label ? this.props.label : 'noLabel';
    const defaultValue = this.props.defaultValue ? this.props.defaultValue : undefined;
    const type = this.props.type ? this.props.type : undefined;
    const id = this.props.id ? this.props.id : undefined;
    const autoComplete = this.props.autoComplete ? this.props.autoComplete : undefined;
    const value = 'value' in this.props ? this.props.value : undefined;
    const style = this.props.style ? this.props.style : {};
    const variant = this.props.variant ? this.props.variant : 'filled';
    return (
      <div>
        <TextField
          fullWidth={true}
          type={type}
          autoComplete={autoComplete}
          size="small"
          variant={variant}
          id={id}
          value={value}
          label={label}
          defaultValue={defaultValue}
          onChange={(event) => this.handleChange(event.target.value)}
          style={style}
        />
      </div>
    );
  }
}

export default TextFieldBox;
